<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab
        href="#tab-categoria"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Categoria
        <i :class="$utilidade.getIcone('categoria') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-meioPropagacao"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Meio de Propagação
        <i :class="$utilidade.getIcone('meioPropagacao') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-unidade"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Unidade de Medida
        <i :class="$utilidade.getIcone('unidade') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-classificacao"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Classificação
        <i :class="$utilidade.getIcone('classificacao') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-frequencia"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Frequência
        <i :class="$utilidade.getIcone('frequencia') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <parametro
      v-show="strTab == 'tab-categoria'"
      strChave="riscoCategoria"
      @salvar="initialize"
      :parametro="bind.objParametro.riscoCategoria"
      :key="'categoria-' + $root.$session.versao"
    />

    <parametro
      v-if="strTab == 'tab-meioPropagacao'"
      strChave="riscoMeioPropagacao"
      @salvar="initialize"
      :parametro="bind.objParametro.riscoMeioPropagacao"
      :key="'meioPropagacao-' + $root.$session.versao"
    />

    <parametro
      v-if="strTab == 'tab-unidade'"
      strChave="riscoUnidade"
      @salvar="initialize"
      :parametro="bind.objParametro.riscoUnidade"
      :key="'unidade-' + $root.$session.versao"
    />

    <parametro
      v-if="strTab == 'tab-classificacao'"
      strChave="riscoClassificacao"
      @salvar="initialize"
      :parametro="bind.objParametro.riscoClassificacao"
      :key="'classificacao-' + $root.$session.versao"
    />

    <parametro
      v-if="strTab == 'tab-frequencia'"
      strChave="riscoFrequencia"
      @salvar="initialize"
      :parametro="bind.objParametro.riscoFrequencia"
      :key="'frequencia-' + $root.$session.versao"
    />
  </div>
</template>

<script>
import Parametro from "@/components/common/parametro/editar";

export default {
  name: "pageCadastroFuncao",
  props: {
    bind: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    Parametro,
  },
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      strTab: "tab-categoria",
    };
  },
  watch: {},
  methods: {
    initialize(formData) {
      if (formData) {
        this.bind.objParametro[formData.strChave] = formData;
      }
    },
  },
};
</script>
