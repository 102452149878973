<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-exame"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Vincular Exames Sugeridos
        <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-gerenciar"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Outros Cadastros
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="strTab == 'tab-cadastro' ? salvar : null"
      v-if="!this.$root.$session.loadingContent && boolCarregado"
    >
      <cadastro-geral
        :formData="formData"
        v-show="strTab == 'tab-cadastro' && bind.objParametro"
        :bind="bind"
        :key="'cadastro-' + $root.$session.versao"
      />

      <gerenciar
        :formData="formData"
        v-if="strTab == 'tab-gerenciar' && bind.objParametro"
        :bind="bind"
        :key="'gerenciar-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import { EdForm } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";
import Gerenciar from "./partials/gerenciar.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intRiscoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    Gerenciar,
  },
  provide() {
    return {
      bind: this,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      boolCarregado: false,
      bind: {
        objParametro: null,
      },
      formData: {
        intId: 0,
        strCbo: null,
        strNome: null,
        strDescricao: null,
        boolAtivo: 1,
        campos_personalizados: [],
      },
    };
  },
  watch: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Common/Parametro", {
          arrayParametro: [
            "riscoUnidade",
            "riscoMeioPropagacao",
            "riscoMetodologia",
            "riscoCategoria",
          ],
          boolBuscarSomenteAtivo: false,
        })
        .then((objResponse) => {
          if (objResponse && objResponse.status == 200) {
            let objParametro = {
              riscoUnidade: objResponse.result.riscoUnidade,
              riscoMeioPropagacao: objResponse.result.riscoMeioPropagacao,
              riscoMetodologia: objResponse.result.riscoMetodologia,
              riscoCategoria: objResponse.result.riscoCategoria,
            };

            this.bind.objParametro = Object.assign(objParametro, {});
            this.boolCarregado = true;
          }
          this.getRisco();
        });
    },

    getRisco() {
      if (!this.intRiscoId) {
        this.boolCarregado = true;
        return;
      }
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/Risco", { intId: this.intRiscoId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let meio_propagacao = [];
            objResponse.result.meio_propagacao.forEach((objElement) => {
              meio_propagacao.push(objElement.intParametroMeioPropagacaoId);
            });

            let formData = Object.assign(objResponse.result, {});

            formData.meio_propagacao = meio_propagacao;

            this.formData = formData;
          }
        });
    },

    salvar() {
      this.$root.$request.post("Cadastro/Risco", this.formData).then((objResponse) => {
        if (objResponse.status == 200) {
          if (this.$route.name != "cadastro.risco.edit") {
            this.$router.push({
              name: "cadastro.risco.edit",
              params: { intId: objResponse.result.intId },
            });
          } else {
            this.getRisco();
          }
        }
      });
    },
  },
};
</script>
