<template>
  <div class="row ma-0 pa-0" v-if="bind.objParametro">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Informações do Risco</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          required
          rules="required"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strCodigoEsocial"
          label="Código eSocial"
          name="strCodigoEsocial"
          item-text="strNome"
          item-value="strCodigo"
          route="Esocial/EsocialTabelaConteudo"
          :filters="{
            strBusca: this.formData.strCodigoEsocial,
            intEsocialTabelaId: 23,
          }"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intParametroCategoriaId"
          name="intParametroCategoriaId"
          label="Categoria"
          :items="bind.objParametro.riscoCategoria.parametros"
          required
          rules="required"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.meio_propagacao"
          name="meio_propagacao"
          label="Meio de Propagação"
          :items="bind.objParametro.riscoMeioPropagacao.parametros"
          multiple
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intParametroMetodologiaId"
          name="intParametroMetodologiaId"
          label="Metodologia"
          :items="bind.objParametro.riscoMetodologia.parametros"
        />

        <ed-input-time
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strTempoExposicao"
          name="strTempoExposicao"
          label="Tempo de Exposição"
        />

        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.boolAtivo"
          name="boolAtivo"
          label="Risco ativo?"
          required
          rules="required"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Limite de Tolerância</span>

      <div class="row">
        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.floatLimiteTolerancia"
          name="floatLimiteTolerancia"
          label="Limite de Tolerância"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.intParametroLimiteToleranciaId"
          name="intParametroLimiteToleranciaId"
          label="Unidade"
          :items="bind.objParametro.riscoUnidade.parametros"
        />
      </div>

      <span class="form-title">Nível de Ação</span>

      <div class="row">
        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.intParametroNivelAcaoId"
          name="intParametroNivelAcaoId"
          label="Unidade"
          :items="bind.objParametro.riscoUnidade.parametros"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.floatNivelAcao"
          name="floatNivelAcao"
          label="Nível de Ação"
          :disabled="!formData.boolAtivo"
        />
      </div>

      <span class="form-title">Outras informações</span>

      <div class="row">
        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strDadosSaude"
          name="strDadosSaude"
          label="Possíveis danos asaúde"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strMedidaControleProposta"
          name="strMedidaControleProposta"
          label="Medidas de controle proposta"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strDescricaoAgenteNocivo"
          name="strDescricaoAgenteNocivo"
          label="Descrição do Agente Nocivo"
          :disabled="!formData.boolAtivo"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <campos-personalizado
        :formData="formData"
        :key="'campo-' + $root.$session.versao"
        :intTipoModeloId="311"
        @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
      />
    </div>
  </div>
</template>

<script>
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputTime,
  EdInputAutoComplete,
  EdInputMoney,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
    bind: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputTime,
    EdInputAutoComplete,
    EdInputMoney,
    CamposPersonalizado,
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
